
.image-comparison {
    width: 350px;
    height: 350px;
    border-radius: 200px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.text-comparison {
    color: #505559;
    max-width: 950px;
    margin: 0 auto 0px;
    margin-top: 50px;
    font: 300 20px/32px "Arial";
    text-align: left;
}

@media (max-width: 880px) {
    .text-comparison {
        padding: 20px;
    }

}