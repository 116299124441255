
@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(50%,0,0);
        transform: translate3d(50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(50%,0,0);
        transform: translate3d(50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}









@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-50%,0,0);
        transform: translate3d(-50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-50%,0,0);
        transform: translate3d(-50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}


.content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 1s;
}


.box {
    position: absolute;

    /*todo default*/
    /*
    left: 1%;
    top: 13%;
    /**/


    left: 62%;
    /**/
    width: 560px;
    height: 282px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 5px;
}

.teaser-title {
    color: #374048;
    text-transform: uppercase;
    font: 600 35px/29px "Arial";
    text-align: justify;
    line-height: 1;

}

.teaser-description {
    color: #505559;
    font: 300 20px/32px "Arial";
    display: block;

    margin-top: 10px;
    text-align: justify;


}

.teaser-button {
    float: left;

}


.teaser-button {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 5rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 0;
    bottom: 50px;
}
.teaser-button:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #005293;
    border-radius: 10rem;
    z-index: -2;
}
.teaser-button:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #003259;
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
}
.teaser-button:hover {
    color: #fff;
    cursor: pointer;
}
.teaser-button:hover:before {
    width: 100%;
}
/* optional reset for presentation */


@media (min-width: 1301px) {
    * {
        font-family: Arial;
        text-decoration: none;
        font-size: 20px;
    }
}

@media (max-width: 1300px) {
    * {
        font-family: Arial;
        text-decoration: none;
    }
}

.container {
    padding-top: 50px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
h1 {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    color: #777;
}





/*???*/
.button-text {

}
