.element-is-selected {
    /*todo change topbar to blue*/
    color: #005293;/**/
    /*color: white;/**/
    margin-top: 7px;
    padding: 0 17px;
    text-transform: uppercase;
    font: 700 16px/40px "Arial";
    float: right;
    cursor: pointer;
    transition: all 300ms linear 0s;




    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Safari, Chrome, Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}


@media (max-width: 1614px) {
    .element-is-selected {

        visibility: hidden;
    }

}

