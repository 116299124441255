.video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 0em;
    width: 3em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin-top: -0.81666em;
    cursor: pointer;
    opacity: 1;
    border: 0;
    background-color: rgba(0, 0, 0, 0.0);
    border-radius: 0;
    transition: all 0.4s;
}



.video-js:hover .vjs-big-play-button:hover {
    background-color: rgba(0, 0, 0, 0.0);
}

.vjs-icon-play:before, .video-js .vjs-play-control .vjs-icon-placeholder:before, .video-js .vjs-big-play-button {
    content: "\f101";
    color: white;
}

.vjs-big-play-button .vjs-icon-placeholder:before{
    content: "\f101";
    color: rgba(255, 255, 255, 0);
}




.field {
    position: relative;
    width: 1320px;
    height: 742px;
    pointer-events: all;
}

.field-for-button {
    width: 1320px;
    height: 742px;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.29);
    cursor: pointer;
    transition: all 300ms linear 0s;
}

.play-button {
    content: "\f107";
    font-size: 200px;
    text-align: center;
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    background-color: rgba(102, 51, 153, 0);
    pointer-events: none;
    position: relative;
    z-index: 2;
    transition: all 300ms linear 0s;
}


.vjs-remaining-time-display span {
    display: unset;
    color: white;
}

.vjs-control-bar {
    z-index: 3;
    background-color: rgba(36, 39, 47, 0.5);
}


.play-button-mobile {
    display: none;
}

.field-for-button-mobile {
    display: none;
}

@media(max-width: 1320px) {
    .field {
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0);
    }

    .field-for-button {
        width: 100%;
        height: auto;
        background-color: rgba(0, 0, 0, 0);
        z-index: 1000;
    }



    .play-button {
        opacity: 0;
    }

    .play-button-mobile {
        position: absolute;
        margin-left: 25%;
        margin-right: auto;
        top: 0;
        width: 50%;
        display: block;
    }

    .field-for-button-mobile {
        position: absolute;
        width: 100%;
        height: 0%;
        padding-top: 56%;
        background-color: rgba(0, 0, 0, 0.29);
        z-index: 999;
        pointer-events: none;
        display: block;
    }

}