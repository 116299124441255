

.modal-component {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    pointer-events: none;
}

.modal {
    width: 500px;
    height: max-content;
    background-color: white;
    border-radius: 30px;
    transition: all 300ms;


    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 10px;
    padding-bottom: 30px;
}

.modal.nonactive {
    top: 0px;
    opacity: 0;
    pointer-events: none;
}

.modal.active {
    /*top:525px;*/
    opacity: 1;
    pointer-events: all;
}

.modal-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.13);
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.modal-overlay.nonactive {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.13);
}

.modal-overlay.active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.13);
}

.modal-param-title {
    position: static;
    color: #2B333F;
    text-align: left;
    margin: 1px;
    font-size: 16px;
    font-weight: 600;
}

input {
    margin-left: auto;
    margin-right: auto;
    position: static;
    width: 93%;
    border-radius: 15px;
    border: 2px solid rgba(91, 91, 91, 0.51);
    font-size: 18px;
    padding: 5px;
    padding-left: 18px;
    padding-bottom: 6px;
}

input:focus {
    border: 2px solid rgba(91, 91, 91, 0.64);
}

input:active {
    border: 2px solid rgba(91, 91, 91, 0.64);
}

.modal-title {
    margin-left: 40px;
    margin-top: 15px;
    color: #2B333F;

    font-weight: 800;
    text-align: left;
    width: 300px;
    padding-bottom: 20px;
}

.modal-param-space {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 10px;
    border-radius: 200px;
}

.upper-modal-divider {
    background-color: rgba(91, 91, 91, 0.12);
    height: 2px;
    margin-top: 0px;
    margin-bottom: 5px;
}

.under-modal-divider {
    background-color: rgba(91, 91, 91, 0.12);
    height: 2px;
    margin-top: 14px;
    margin-bottom: 25px;
}

.cancel-button {
    opacity: 0.5;
    transition: all 200ms;
    width: 29px;
    margin-bottom: 3px;
    padding-top: 15px;
    float: right;
    cursor: pointer;


    margin-left: auto;
    margin-right: 45px;
}

.cancel-button:hover {
    opacity: 1.5;
}

.modal-header {
    display: flex;
}

#send-button {
}







.select-a-file-text {
    color:#1B96FF;
    font-size: 16px;
}

.select-a-file-text-black {
    color: #000000;
    font-size: 16px;
    padding-left: 4px;
}


.select-a-file{
    display:flex;
    gap:3px;
    justify-content: center;

}

.select-a-file p{
    margin: 10px 0;
}

.background-input {
    margin-left: auto;
    margin-right: auto;
    width: 410px;
    height: 150px;
    position: relative;
    border-width: 2px;
    border-style: dashed;
    border-radius: 5px;
    text-align: center;
    background: white;
    border-color: rgb(208, 215, 222);
    margin-top: 10px;
}
.background-input img{
    margin-top:40px;
}
.background-input:hover{
    cursor: pointer;
}

.input-file:hover{
    cursor: pointer;
}

.before-upload-field {

}

.after-upload-field {
    margin-top:20px
}

.input-file {
    border-color: rgb(208, 215, 222);
    border-radius: 10px;
    resize: none;
    border-style: solid;
    position: absolute;
    inset: 0;
    opacity: 0;
    margin-bottom:0;
}





.product-submit-link-button {
    text-decoration: none;
    outline: none;
    transition: all 150ms linear 0s;
}

.product-submit-link-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 15px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 150px;
}

.product-submit-link-button:hover,.product-submit-link-button:focus {
    background: #0173cc;
    border-color: #0173cc;
    color: #fff
}


.product-submit-link-button:hover,.product-submit-link-button:focus {
    text-decoration: none;
    outline: none
}

.product-submit-link-button {
    border-radius: 5px;
    font: 300 16px/26px "Poppins",sans-serif;
    padding: 6px 63px;
    background: #005293;
    border-color: #005293;
    color: #fff
}

.product-submit-link-button {
    border-radius: 10px;
    margin-top: 5px;
}


@media (max-width: 1300px) {

    .modal-component {
        z-index: 1000000;
    }
    .modal.active {
        width: 90%;
        opacity: 1;
        pointer-events: all;
        top: 50%;
        left: 50%;
    }

    .cancel-button {
        margin-left: auto;
        margin-right: 45px;
    }

    .background-input {
        width: 100%;
        height: 120px;
    }

    .product-submit-link-button {
        top: 0;
        margin-top: 20px;
    }

    .under-modal-divider {
        display: none;
    }

}



@media (max-width: 420px) {
    .select-a-file-text {
        font-size: 12px;
    }

    .select-a-file-text-black {
        font-size: 12px;
    }

    .product-submit-link-button {
        width: 80%;
        padding: 6px;
    }
}

@media (max-width: 340px) {
    .select-a-file-text {
        font-size: 9px;
    }

    .select-a-file-text-black {
        font-size: 9px;
    }
}


@media (max-height: 833px) {
    .modal-param-input {
    }

    .modal-param-input-red {
    }


    .modal-param-title {
        font-size: 14px;
    }
}


@media (max-height: 800px) {
    .modal.active {
        opacity: 1;
        pointer-events: all;
        top: 50%;
        left: 50%;
    }

    .modal-param-input {
    }

    .modal-param-input-red {
    }

    .modal-param-title {
        font-size: 50%;
    }

    .modal-param-space {
        padding-top: 7px;
    }
}




.red-modal-param-input {
    border-color: #fc2424;
}

.modal-param-input:focus {
    outline: none;
}

.red-modal-param-input:focus {
    border-color: #fc2424;
    outline: none;
}

.red-error {
    height: 14px;
    display: inline-block;
    position: absolute;
    margin-top: 2px;
    margin-right: 100%;
}

.red-subscription {
     color: #fc2424;
     font-size: 10px;
     margin-right: 100%;
     width: 200px;
     display: inline-block;
     position: absolute;
    margin-top: 3px;
}

.red-composition {
    width: 0px;
}



@media (max-width: 1300px) {
    .red-error {
        margin-top: 3px;
    }
}