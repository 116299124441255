
.image-link {
    width: 350px;
    height: 350px;
    border-radius: 200px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.text-link {
    color: #505559;
    max-width: 1000px;
    margin: 0 auto 0px;
    margin-top: 50px;
    font: 300 20px/32px "Arial";
    text-align: justify;
}

.product-links {
    margin-left: -6%;
    width: 1500px;
    padding-top: 15px;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
    margin-bottom: 100px;
}

@media (min-width: 1500px) {
    .control-buttons {
        visibility: hidden;
    }

    .control-buttons-space {
        visibility: hidden;
    }

}

@media (max-width: 1500px) {
    .product-links {
        overflow-x: scroll;
    }


    .control-buttons {
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    .control-buttons-space {
        position: absolute;
        width:  108%;
        height:  566px;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        right: -50px;
    }
}

.product-links:hover {

}

.product-links div {
    display: inline-block;
}



.arrow-button {
    color: rgba(255, 255, 255, 0.5);
    width: 64px;
    height: 64px;
    position: relative;
    cursor: pointer;
    pointer-events: all;
    opacity: 0.6;
    transition: all 100ms linear 0s;
}

.arrow-button:hover {
    opacity: 1;
}

.arrow-button-left-image {
    margin-top: 9.5px;
    right:  8px;
}

.arrow-button-right-image {
    margin-top: 9.5px;
    margin-left: 4px;
}

@media (max-width: 1614px) {
    .product-links {
        width: 1014px;
        margin-left: auto;
        margin-right: auto;
    }
    .control-buttons-space {
        position: absolute;
        width:  100%;
        height:  566px;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        left: 0px;
    }
}

@media (max-width: 1116px) {
    .product-links {
        width: 676px;
    }
}

@media (max-width: 730px) {
    .product-links {
            width: 338px;
    }
}

