


.topBar {
    position: fixed;
    width: 100%;
    height: 100px;
    transition: all 300ms linear 0s;
    display: flex;
    justify-content: center;
    z-index: 1000000;
    /*todo change topbar to blue*/
    background: rgb(255, 255, 255);/**/
    /*background: #005293;/**/
    box-shadow: 0 2px 42px rgba(0,0,0,.1);
}


.topBar.is-scrolled {
    background: #fff;
    box-shadow: 0 2px 42px rgba(0,0,0,.1);
}

.offset {
    margin-left: auto;
    margin-right: auto;
    width: 78%;
    margin-top: 20px;
}
.image-div {
    position: relative;
    width: 204px;
    height: 40px;
    float: left;
}


.logo-color {
    width: 304px;
    height: 60px;
    margin-top: -9px;
    margin-left: 20px;
    opacity: 1; /*todo change photo*/
    transition: all 300ms linear 0s;
}

/*todo change photo*/
/*
.topBar .offset .element {
    margin-top: 7px;
    padding: 0 17px;
    text-transform: uppercase;
    font: 700 16px/40px "Arial";
    float: right;
    margin-right: 10px;
    cursor: pointer;
    color: white;
    transition: all 300ms linear 0s;


    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}*/


.topBar .offset .element {
    margin-top: 7px;
    padding: 0 17px;
    text-transform: uppercase;
    font: 700 16px/40px "Arial";
    float: right;
    cursor: pointer;
    /*todo change topbar to blue*/
    /*color: #fff;*/
    color: #8f969d;
    transition: all 300ms linear 0s;
    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Safari, Chrome, Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.topBar.is-scrolled .offset .element {
    margin-top: 7px;
    padding: 0 17px;
    text-transform: uppercase;
    font: 700 16px/40px "Poppins",sans-serif;
    float: right;
    margin-right: 10px;
    cursor: pointer;
    /*todo change topbar to blue*/
    /*color: #fff;*/
    color: #8f969d;
    transition: all 300ms linear 0s;
    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Safari, Chrome, Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}


.topBar .offset :last-child {
    margin-right: 0;
}

.topBar.is-scrolled .offset :last-child {
    margin-right: 0;
}

@media (max-width: 1614px) {
    .topBar .offset .element {
        visibility: hidden;
    }

    .topBar {
        width: 100%;
        position: fixed;
        margin: 0;
        left: 0;
    }

    .logo-color {
        margin-top: -9px;
        margin-left: 20px;
        opacity: 1; /*todo change photo*/
        transition: all 300ms linear 0s;
    }
}

@media (min-width: 1614px) {
    .topBar .offset .element {
        visibility: visible;
    }
}

@media (max-width: 900px) {

    .logo-color {
        margin-top: 4px;
        margin-left: -10px;
        opacity: 1; /*todo change photo*/
        transition: all 300ms linear 0s;
        width: 260px;
        height: auto;
    }
}

@media (max-width: 560px) {

    .logo-color {
        margin-top: 10px;
        margin-left: -60px;
        opacity: 1; /*todo change photo*/
        transition: all 300ms linear 0s;
        width: 180px;
        height: auto;
    }
}

@media (max-width: 350px) {

    .logo-color {
        margin-top: 10px;
        margin-left: -100px;
        opacity: 1; /*todo change photo*/
        transition: all 300ms linear 0s;
        width: 150px;
        height: auto;
    }
}

@media (max-width: 250px) {

    .logo-color {
        margin-top: 20px;
        margin-left: -130px;
        opacity: 1; /*todo change photo*/
        transition: all 300ms linear 0s;
        width: 100px;
        height: auto;
    }
}

.sideBar {
    visibility: hidden;
    pointer-events: none;
}
@media (max-width: 1614px) {
    .sideBar {
        visibility: visible;
        pointer-events: all;
    }
}

a {
    text-decoration: none;
}