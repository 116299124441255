
.main-construction {
    position: relative;
    width: 100%;
}


.tooltips-construction {
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.image-construction {
    margin-top: 25px;
    width: 700px;
    height: auto;
    position: relative;
}

.tooltips-construction  span {
    display: block;
    margin-top: 2rem;
    font-size: 0.7rem;
    color: #777;
}
.tooltips-construction span a {
    font-size: 0.7rem;
    color: #999;
    text-decoration: underline;
}


@media (min-width: 1286px) {
    .main-construction {
        top: 50px
    }

}
