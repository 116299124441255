.how-to-buy-image {
    display: inline-block;
    width: 60%;
    background-color: rgba(0, 82, 147, 0.04);
}

@media (max-width: 1300px) {
    .how-to-buy-image {
        width: 100%;
    }
}

.how-to-buy-image-content {
    width: 95%;
}