.product-link-component {
    padding: 30px;
    text-align: center;
}

.product-link-image-field {
    background-color: rgba(0, 82, 147, 0.04);
    width: 278px;
    height: 278px;
    margin-bottom: 30px;
    display: flex;
    border-radius: 20px;
}

.product-link-image-subfield {
    display: flex;
}

.product-link-image {
    width: 90%;
    margin: auto;
    margin-top: 10px;
}

.product-link-title {
    color: #4e525b;
    font: 700 20px/1 "Arial",sans-serif;
    margin: 0;
}

.product-link-category {
    color: #7b818d;
    margin: 5px 0 0;
    font: 300 17px/1 "Arial",sans-serif;
    margin-top: 12px;
}

.product-link-price {
    color: #005293;
    font: 600 23px/1 "Arial",sans-serif;
    margin: 14px 0 20px;
}



.product-ozon-link-button {
    text-decoration: none;
    outline: none;
    transition: all 150ms linear 0s;
}

.product-ozon-link-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 15px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 400px;
}

.product-ozon-link-button:hover,.product-ozon-link-button:focus {
    background: #0173cc;
    border-color: #0173cc;
    color: #fff
}


.product-ozon-link-button:hover,.product-ozon-link-button:focus {
    text-decoration: none;
    outline: none
}

.product-ozon-link-button {
    font: 300 16px/26px "Poppins",sans-serif;
    padding: 6px 63px;
    background: #005293;
    border-color: #005293;
    color: #fff
}

.product-ozon-link-button {
    margin-top: 5px;
}




.product-vi-link-button {
    text-decoration: none;
    outline: none;
    transition: all 150ms linear 0s;
}

.product-vi-link-button {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 15px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 120px;
}

.product-vi-link-button:hover,.product-vi-link-button:focus {
    background: #cc0101;
    border-color: #cc0101;
    color: #fff
}


.product-vi-link-button:hover,.product-vi-link-button:focus {
    text-decoration: none;
    outline: none
}

.product-vi-link-button {
    border-radius: 5px;
    font: 300 16px/26px "Poppins",sans-serif;
    padding: 6px 63px;
    background: #930000;
    border-color: #930000;
    color: #fff
}

.product-vi-link-button {
    border-radius: 10px;
    margin-top: 5px;
}





.product-link-dropdown-button {
    padding-top:0px;
    float: right;
}

.outer-menu {
    position: relative;
}

.inner-menu {
    position: absolute;
    left: -40px;
    top: -20px;
}


.menu {
    display: inline-block;
    margin: 0 auto;
    list-style-type: none;
}
.menu a {
    display: block;
    margin: 0;
    padding: 16px 25px;
    color: #333;
    text-decoration: none;
}
.menu li {
    position: relative;
    float: left;
    margin: 0;
    border-left: 1px solid #eee;
    perspective: 200;
}
.menu li:first-child {
    border-left: none;
}
.menu li.parent:before {
    content: '';
    z-index: 200;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: -4px;
    margin-left: -20px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
    transition: margin-top 0.1s ease-out;
}
.menu li.active > a, .menu li.active > a {
    text-decoration: none;
    color: #fff;
}
.menu li.active:before, .menu li.active:before {
    margin-top: 0;
}
.menu li.active .children, .menu li.active .children {
    opacity: 1;
    transform: rotateX(0) translateZ(0);
}
.menu .children {
    opacity: 0;
    backface-visibility: hidden;
    transform: rotateX(-90deg);
    transform-style: preserve-3d;
    transform-origin: top center;
    transition: transform 0.4s cubic-bezier(0.17, 0.67, 0.59, 1.21), opacity 0.1s 0.1s;
    z-index: 100;
    list-style-type: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 528px;
    margin: 0;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    text-align: left;

    padding-bottom: 0;

}
.menu .children li {
    float: none;
}
.menu .children a {
    margin-left: 30px;
    background-color: transparent;
    font-size: 20px;
}
.menu .children a.active, .menu .children a.active {
    color: #333;
    background-color: #f9f9f9;
}
.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
body {
    text-align: center;
}

.link-text-field {
}

.link-text {
    font-size: 20px;
    left: 10px;
}

.link-icon-div {
    position: relative;
}


.link-icon {
    position: absolute;
    top: 14px;
    width: 35px;
}

.link-li {
    font-size: 20px;
}

.link-li:hover {
    transition: all 0.2s;
    background-color: rgba(147, 144, 144, 0.11);
    cursor: pointer;
}


@media (max-width: 1300px) {

    .product-link-dropdown-button {
        width: 100%;
        float: none;
    }

    .product-ozon-link-button {
        width: 100%;
        float: none;
        padding: 6px;
        border-radius: 0;
    }

    .inner-menu {
        width: 100%;
        left: 0;
        right: 0;
        height: 0;
    }

    .menu {
        width: 100%;
        padding-left: 0;
    }

    .parent {
        width: 100%;
        left: 0;
        margin-left: 0;
    }

    .menu .children {
        width: 100%;
    }
}


.link-li-div {
    width: 242px;
    margin-left: auto;
    margin-right: auto;
}


@media (max-width: 1300px) {
    .link-li-div {
        width: 242px;
        margin-left: auto;
        margin-right: auto;
    }
    .outer-menu {
        margin-top: 3px;
    }
}
