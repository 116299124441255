.mobile-carousel-foo {
}

.mobile-carousel-foo-image {
    width: 100%;
    height: 50px;
}


.willClose {
    transition: all 100ms;
}