.desktop-how-to-buy-component {
    margin-left: auto;
    margin-right: auto;
    width: 1320px;
    padding-top: 50px;
    display: flex;
    padding-bottom: 150px;
}


.mobile-how-to-buy-component {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-top: 40px;
    display: none;
    padding-bottom: 150px;
}


@media (max-width: 1300px) {
    .mobile-how-to-buy-component {
        width: 100%;
        display: block;
    }


    .desktop-how-to-buy-component {
        display: none;
    }

}


