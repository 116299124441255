@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.image-field-advantages {
    width: 450px;
    display: inline-block;
    position:relative;
}



.image-field-advantages-element {
    display: inline-block;
    position: absolute;
    width: 500px;
    bottom: -340px;
    left: 55px;
}

.text-field-advantages {
    width: 555px;
    display: inline-block;
    position:relative;
    float: left;


}

.advantages-component {
    margin-left: auto;
    margin-right: auto;
    width: 1322px;
    padding-top: 0px;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}


.title-advantages {
    color: #374048;
    text-transform: uppercase;
    font: 600 48px/29px "Arial";
    padding-top: 5px;
    margin: 0 0 25px;
    text-align: justify;
    line-height: 1;

}

.description-advantages {
    color: #6f757b;
    max-width: 455px;
    margin: 0 0 20px;
    font: 300 20px/32px "Arial";
    text-align: justify;

}


.advantages {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
}

.advantage {
    min-width: 795px;
    position: relative;
    margin-bottom: 22px;
}

.advantage-text {
    color: #505559;
    font: 300 30px/32px "Arial";
    display: inline-block;
    float: left;
    line-height: 1;
    text-align: left;
}

@media (min-width: 1300px) {

    .advantage-text {
        width: 720px;
    }
}

@media (max-width: 1300px) {
    .advantage-text {
        margin-top: 5px;
        font-size: 20px;
    }
    .advantages {
        padding-left: 20px;
    }
}

@media (max-width: 470px) {
    .advantage-text {
        margin-top: 2px;
        font-size: 22px;
    }
}


.advantage-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 17px;
    float: left;
}

@media(min-width: 1300px) {

    .text-field-advantages {
        padding-bottom: 30px;
    }
}



@media(max-width: 1300px) {
    .advantages-component {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: flex;
        padding-top: 0px;
    }

    .text-field-advantages {
        width: 100%;
        display: inline-block;
        position: relative;
        float: left;
        text-align: justify;
    }

    .image-field-advantages {
        width: 100%;
        display: inline-block;
        position: relative;
        float: right;
    }


    .image-field-advantages-element {
        display: unset;
        position: static;
        width: 100%;
        height: auto;
        bottom: 0;
        left: 0;
    }

    .advantages {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
    }

    .advantage {
        width: 100%;
        min-width: 100%;
        display: flex;
    }

    .advantage-text {
        padding-right: 20px;
    }
}


@media(max-width: 1300px) and (min-width: 900px) {
    .image-field-advantages {
        bottom: 100px;
    }
}



@media(max-width: 790px) {
    .advantages-component {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        display: block;
    }

    .text-field-advantages {
        width: 100%;
        display: inline-block;
        position: relative;
        float: left;
        text-align: justify;
    }

    .advantages {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
    }

    .advantage {
        width: 100%;
        min-width: 100%;
        display: flex;
    }


    .image-field-advantages {
        width: 100%;
        display: inline-block;
        position: relative;
    }


    .image-field-advantages-element {
        display: unset;
        position: static;
        width: 100%;
        height: auto;
        bottom: 0;
        left: 0;
    }
}


@media(max-width: 480px) {
    .title-advantages {
        font: 600 42px/29px "Arial";

    }

    .description-advantages {
        font: 300 18px/32px "Arial";

    }

}


@media(max-width: 390px) {
    .title-advantages {
        font: 600 35px/29px "Arial";

    }

    .description-advantages {
        font: 300 15px/32px "Arial";

    }
}


@media(max-width: 345px) {
    .title-advantages {
        font: 600 30px/29px "Arial";

    }

    .description-advantages {
        font: 300 13px/32px "Arial";
    }
}
