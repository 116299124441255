.how-to-use-element {
}


.how-to-use-element-image-field {
    width: 92%;
    float: right;
    display: block;
}

.how-to-use-element-image {
    padding-top: 20px;
    width: 100%;
}



.how-to-use-element-image-for-shadow {
    position: relative;
}


.how-to-use-element-text {
    display: block;
    position: relative;
    height: max-content;
    width: 100%;

}


.how-to-use-element-text-centered {
    vertical-align: top;
    align-items: center;
}


.how-to-use-element-title {
    display: block;
    margin-top: 0px;
    margin-right: 15px;
    font-weight: 700;
    font-size: 35px;
    font-family: inherit;
    color: #374048;
}

.how-to-use-element-description {
    width: 450px;
    color: #505559;
    margin: 0;
    font: 600 19px/32px "Arial";
    display: block;
    text-align: left;
    padding-left: 20px;
}

@media (max-width: 1300px) {

    .how-to-use-element-text {
        width: 92%;
    }

    .how-to-use-element-description {
        padding-left: 0px;
    }

}


.how-to-use-element-component {
    display: flex;
    vertical-align: top;
    align-items: center;
    position: relative;
}

.how-to-use-element-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 450px;
}

.arrow-field {
    width: 15%;
    display: flex;
    vertical-align: top;
    horiz-align: center;
    align-items: center;
    justify-content: center;
}

.arrow {
    margin-right: 250px;
    position: absolute;
}


@media (min-width: 1250px) {
    .how-to-use-element-image-field:after {
        content: '';
        position: absolute;
        bottom: 2.3%;
        left: 4%;
        width: 92%;
        height: 87%;
        box-shadow: inset 0 0 20px 5px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 1250px) {
    .how-to-use-element-component {
        margin-top: 50px;
        width: 100%;
        height: auto;
    }

    .how-to-use-element-content {
        width: 100%;
    }

    .how-to-use-element-description {
        width: fit-content;
        float: left;
    }

}