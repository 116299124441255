#how-to-use-component {

}

.how-to-use-elements-list {
    justify-content: center;
    vertical-align: middle;

}


.how-to-use-elements-list-horizontal {
    justify-content: center;
    vertical-align: middle;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
}

.how-to-use-elements-list-vertical {

}

.how-to-use-elements-list-group {
    margin-top: 59px;
}

@media (max-width: 1250px) {
    .how-to-use-elements-list-horizontal {
        display: block;
    }

    .section-resizable {
        width: 100%;
    }
}

