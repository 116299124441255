.footer {
    margin-top: 100px;
    width: 90%;
    height: 100px;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
}

.footer-divider {
    background: rgba(164, 166, 173, 0.49);
    color: #e9eef2;
    width: 100%;
    height: 1px;

}

.footer-copyright {
    margin-top: 15px;
    color: rgba(131, 135, 141, 0.65);
    font: 300 17px/1 "Arial";
    text-align: center;

}
