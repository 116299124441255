.image-main {
    width: 835px;
    height: 540px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
}

.text-main {
    color: #505559;
    max-width: 1320px;
    margin: 0 auto 0px;
    margin-top: 30px;
    font: 300 20px/32px "Arial";
    text-align: justify;
    visibility: visible;
}

.mobile-text-main {
    display: none;
}

.construction-body {
    display: flex;
}

.main-construction {
    position: relative;
    width: 67%;
    left: 200px;
    top:150px
}

.construction-description {
    margin-top: 450px;
    width: 41%;
}

.mobile-construction {
    display: none;
}

@media(max-width: 1300px) {
    .mobile-text-main {
        color: #505559;
        max-width: 1235px;
        margin-top: 10px;
        font: 300 18px / 27px "Arial";
        text-align: justify;
        visibility: visible;
        display: block;
        padding: 0px 30px 30px;
    }

    .text-main {
        display: none;
    }

    .desktop-construction {
        display: none;
    }

    .mobile-construction {
        display: block;
    }

    .mobile-construction-image {
        width: 100%;
    }
}

.construction-body {
    display: block;
}

.construction-description {
    margin-top: 0;
    width: fit-content;
    padding: 20px;
    padding-top: 200px;
}


@media (min-width: 1301px) and (max-width: 1614px) {
    .construction-description {
        margin-left: auto;
        margin-right: auto;
    }
}


@media(max-width: 1300px) {
    .construction-description {
        padding: 0px;
    }

}

@media(min-width: 650px) {
    .advanced-description-outer {
        position: relative;
    }

    .advanced-description-inner {
        position: absolute;
        bottom: 15px;
        left: 60%;
        width: 41%;
    }
}


@media(max-width: 649px) {
    .advanced-description-outer {
        margin-top: 0;
        width: fit-content;
        padding: 20px;
        padding-top: 40px;
    }
}

.advanced-description-inner {
    display: flex;
    flex-direction: column;
}


.advanced {
    min-width: 755px;
    position: relative;
    margin-bottom: 22px;
}

.advanced-text {
    display: inline-block;
    width: 300px;
    float: left;
    color: #374048;
    max-width: 1235px;
    margin: 0 auto 0px;
    font: 300 20px/32px "Arial";
    text-align: left;
}

.advanced-icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 17px;
    content: '';
    float: left;
    margin-top: -2px;
}


#main-description {

}
