@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
}


.section {
    width: 1350px;
    padding-top: 110px;
    padding-bottom: 110px;
    padding-left: 40px;
    padding-right: 40px;
    animation-duration: 1s;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);

}

.section-blue {
    width: 100%;
    padding-top: 110px;
    padding-bottom: 110px;
    padding-left: 40px;
    padding-right: 40px;
    background: rgba(248, 251, 253, 0.7);
    animation-duration: 1s;
    z-index: 1;
}

.section-resizable {
    padding-top: 110px;
    padding-bottom: 110px;
    padding-left: 40px;
    padding-right: 40px;
    animation-duration: 1s;
    z-index: 1;
    background: rgba(255, 255, 255, 0.7);
}

.section-blue-resizable {
    padding-top: 110px;
    padding-bottom: 110px;
    padding-left: 40px;
    padding-right: 40px;
    animation-duration: 1s;
    z-index: 1;
    background: rgba(248, 251, 253, 0.7);
}


.title {
    color: #374048;
    text-transform: uppercase;
    font: 600 62px/29px "Arial";
    padding-top: 5px;
    margin: 0 0 25px;
    opacity: 1;
    line-height: 1;

}

.description {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #374048;
    max-width: 1000px;
    margin: 0 auto 20px;
    margin-top: 59px;
    font: 400 30px/32px "Arial";
    opacity: 1;
    text-align: justify;
    line-height: 1.5;
    visibility: visible;
}

.mobile-description {
    display: none;
}



@media(max-height: 1100px) {

    .section {
        width: 1350px;
        padding-top: 90px;
        padding-bottom: 0px;
        padding-left: 40px;
        padding-right: 40px;
        animation-duration: 1s;
        z-index: 1;
        background: rgba(255, 255, 255, 0.7);

    }

    .section-blue {
        width: 100%;
        padding-top: 90px;
        padding-bottom: 0px;
        padding-left: 40px;
        padding-right: 40px;
        background: rgba(248, 251, 253, 0.7);
        animation-duration: 1s;
        z-index: 1;
    }

    .section-resizable {
        padding-top: 90px;
        padding-bottom: 90px;
        padding-left: 40px;
        padding-right: 40px;
        animation-duration: 1s;
        z-index: 1;
        background: rgba(255, 255, 255, 0.7);
    }

    .section-blue-resizable {
        padding-top: 90px;
        padding-bottom: 90px;
        padding-left: 40px;
        padding-right: 40px;
        animation-duration: 1s;
        z-index: 1;
        background: rgba(248, 251, 253, 0.7);
    }
}


@media(max-width: 1300px) {

    .mobile-description {
        color: #374048;
        max-width: 1000px;
        margin: 0 auto 20px;
        margin-top: 59px;
        font: 400 30px/32px "Arial";
        opacity: 1;
        text-align: justify;
        line-height: 1.5;
        padding: 20px 30px;
        display: block;
    }

    .description {
        display: none;
    }


}


@media(max-width: 480px) {
    .title {
        color: #374048;
        text-transform: uppercase;
        font: 600 42px/29px "Arial";
        padding-top: 5px;
        margin: 0 0 25px;
        opacity: 1;
        line-height: 1;

    }

    .mobile-description {
        color: #6f757b;
        max-width: 1000px;
        margin: 0 auto 20px;
        margin-top: 59px;
        font: 300 14px/32px "Arial";
        opacity: 1;
        text-align: justify;

    }

}


@media(max-width: 390px) {
    .title {
        color: #374048;
        text-transform: uppercase;
        font: 600 34px/29px "Arial";
        padding-top: 5px;
        margin: 0 0 25px;
        opacity: 1;
        line-height: 1;

    }

    .mobile-description {
        color: #6f757b;
        max-width: 1000px;
        margin: 0 auto 20px;
        font: 300 15px/32px "Arial";
        opacity: 1;
        text-align: justify;

    }
}


@media(max-width: 345px) {
    .title {
        color: #374048;
        text-transform: uppercase;
        font: 600 30px/29px "Arial";
        padding-top: 5px;
        margin: 0 0 25px;
        opacity: 1;
        line-height: 1;

    }

    .mobile-description {
        color: #6f757b;
        max-width: 1000px;
        margin: 0 auto 20px;
        font: 300 13px/32px "Arial";
        opacity: 1;
        text-align: justify;
    }
}



@media(max-width: 1614px) {
    .section {
        width: 100%;
    }
}

@media (max-height: 730px) {

    .section {
        padding-top: 50px;
    }

    .section-blue {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .section-resizable {
        padding-top: 50px;
    }

    .section-blue-resizable {
        padding-top: 50px;
    }

}


