
@-webkit-keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(50%,0,0);
        transform: translate3d(50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(50%,0,0);
        transform: translate3d(50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight
}


@-webkit-keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-50%,0,0);
        transform: translate3d(-50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-50%,0,0);
        transform: translate3d(-50%,0,0)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}


.minimal-content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 1s;
}


.minimal-box {
    position: absolute;
    left: 55%;
    top: 73%;
    width: 1100px;
    height: 282px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 5px;
}

.minimal-teaser-title {
    color: #ffffff;
    text-transform: uppercase;
    font: 600 58px/29px "Arial";
    text-align: left;
    line-height: 0;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.51);

}

.minimal-teaser-description {
    color: #ffffff;
    font: 600 80px/32px "Arial";
    display: block;

    margin-bottom: 20px;
    text-align: justify;
    line-height: 0.2;
    text-shadow: 0 0 30px rgb(0, 0, 0);
}

/* optional reset for presentation */
@media (min-width: 1301px) {
    * {
        font-family: Arial;
        text-decoration: none;
        font-size: 28px;
        line-height: 1.5;
        /*color: #505559;*/
    }
}

@media (max-width: 1300px) {
    * {
        font-family: Arial;
        text-decoration: none;
        line-height: 1.5;
        font-size: 20px;
        /*color: #505559;*/
    }
}

@media (max-width: 470px) {
    * {
        font-family: Arial;
        text-decoration: none;
        line-height: 1.5;
        font-size: 22px;
        /*color: #505559;*/
    }
}

#text {
    color: #505559;
}

.container {
    padding-top: 50px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
h1 {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    color: #777;
}





/*???*/
.button-text {

}

@media (max-width: 1612px) {
    .minimal-box {
        left: 50%;
        right: 50%;
        top: 55%;
    }
}


@media (max-width: 1414px) {
    .minimal-box {
        left: 60%; /* Позиционирование по центру */
        transform: translateX(-40%); /* Смещение на половину ширины элемента */
        top: 55%;
    }
}

@media (max-width: 843px) {
    .minimal-box {
        left: 73%; /* Позиционирование по центру */
        transform: translateX(-40%); /* Смещение на половину ширины элемента */
        top: 65%;
    }


    .minimal-teaser-title {
        font-size: 41px;
    }

    .minimal-teaser-description {
        font-size: 57px;
    }
}


@media (max-width: 650px) {
    .minimal-box {
        left: 90%; /* Позиционирование по центру */
        top: 65%;
    }


    .minimal-teaser-title {
        font-size: 34px;
    }

    .minimal-teaser-description {
        font-size: 47px;
    }
}



@media (max-width: 510px) {
    .minimal-box {
        left: 110%; /* Позиционирование по центру */
        top: 62%;
    }


    .minimal-teaser-title {
        font-size: 27px;
    }

    .minimal-teaser-description {
        font-size: 37px;
    }
}


@media (max-width: 408px) {
    .minimal-box {
        left: 125%; /* Позиционирование по центру */
        top: 62%;
    }


    .minimal-teaser-title {
        font-size: 21.5px;
    }

    .minimal-teaser-description {
        font-size: 30px;
    }
}


@media (max-width: 324px) {
    .minimal-box {
        left: 155%; /* Позиционирование по центру */
        top: 62%;
    }


    .minimal-teaser-title {
        font-size: 18px;
    }

    .minimal-teaser-description {
        font-size: 25px;
    }
}


@media (max-width: 262px) {
    .minimal-box {
        left: 175%; /* Позиционирование по центру */
        top: 60%;
    }


    .minimal-teaser-title {
        font-size: 18px;
    }

    .minimal-teaser-description {
        font-size: 25px;
    }
}


@media (max-width: 231px) {
    .minimal-box {
        left: 195%; /* Позиционирование по центру */
        top: 58%;
    }


    .minimal-teaser-title {
        font-size: 15px;
    }

    .minimal-teaser-description {
        font-size: 22px;
    }
}


@media (max-width: 206px) {
    .minimal-box {
        left: 205%; /* Позиционирование по центру */
        top: 58%;
    }


    .minimal-teaser-title {
        font-size: 15px;
    }

    .minimal-teaser-description {
        font-size: 22px;
    }
}



@media (max-width: 195px) {
    .minimal-box {
        left: 215%; /* Позиционирование по центру */
        top: 58%;
    }
}


