.parent {
    cursor: pointer;
}


/* Override button hover styles */

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0.2);
}


.control-next.control-arrow:before {
    border-left: 8px solid #fff;
    opacity: 0;
}

.control-prev.control-arrow:before {
    border-left: 8px solid #fff;
    opacity: 0;
}

.control-dots.dot {
    width: 14px;
    height: 14px;
    transition: all 100ms linear 0s;
}

.control-dots.dot.selected {
    width: 16px;
    height: 16px;
    transition: all 100ms linear 0s;
}


@media (min-width: 1615px) {

    .carousel-image img {
        width: 100%;
        position: relative;
    }


    .picture {
        opacity: 0;
        position: absolute;
        right: 0;
    }

    .carousel-updated {

    }

    .carousel-image-outer {
        width: 100%;
        height: 800px;
        overflow: hidden;
        position: relative; /* Позиционирование относительно контейнера */
        padding-top: 100px;
    }

    .carousel-image-inner {
        width:  100%; /* Ширина изображения равна ширине контейнера */
        height:  100%; /* Высота изображения равна высоте контейнера */
        object-fit: cover; /* Изображение будет обрезано, чтобы заполнить контейнер */
    }

    .background-video-inner {
        position: relative;
        margin-left: -490px;
        min-width:  auto;
        min-height:  auto;
        z-index: -1; /* Это установит видео на задний план */
    }
}


@media (max-width: 1614px) {
    .parent {
        width: 100%;
    }


    .carousel-updated {
        width:  100vw;
    }


    .carousel-image img {
        width: 100%;
        position: relative;
    }


    .picture {
        opacity: 0;
        position: absolute;
        right: 0;
    }

    .carousel-image-outer {
        width: 100%;
        /*height: 500px;*/
        overflow: hidden;
        position: relative; /* Позиционирование относительно контейнера */
        padding-top: 100px;
    }

    .carousel-image-inner {
        width:  100%; /* Ширина изображения равна ширине контейнера */
        height:  100%; /* Высота изображения равна высоте контейнера */
        object-fit: cover; /* Изображение будет обрезано, чтобы заполнить контейнер */
    }

    .background-video-inner {
        position: relative;
        margin-left: -490px;
        min-width:  auto;
        min-height:  auto;
        z-index: -1; /* Это установит видео на задний план */
    }

}

button.control-arrow.control-next {
    width: 78px;
}

.PhotoView-Portal {
    z-index: 10000000;
}

@media (max-width: 1300px) {

    .carousel.carousel-slider .control-arrow {
        width: 80px;
    }
}


@media (max-width: 800px) {

    .carousel.carousel-slider .control-arrow {
        width: 50px;
        margin-top: 40px;
    }

}


@media (max-width: 350px) {

    .carousel.carousel-slider .control-arrow {
        width: 35px;
        margin-top: 80px;
    }

}

