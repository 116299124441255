
.image-recommendation {
    width: 350px;
    height: 350px;
    border-radius: 200px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.text-recommendation-div {
    margin-top: 59px;
}

.text-recommendation {
    color: #505559;
    max-width: 1325px;
    margin: 0 auto 0px;
    margin-top: 20px;
    font: 300 20px/32px "Arial";
    text-align: justify;
}

.player-in-work {
    margin-top: 70px;
    display: inline-block;
}

.video-outer-field {
    margin-top: 50px;
    width: 100%;
}

.video-inner-field {
    width: 1320px;
    margin: auto;
}



@media(max-width: 1320px) {
    .video-inner-field {
        width: 100%;
        margin: auto;
    }
}

@media (max-width: 880px) {
    .text-recommendation {
        padding: 20px;
        width: fit-content;
    }

}

@media (max-width: 476px) {
    .video-js .vjs-control {
        width: 2em;
    }

}
