
.image-in-work {
    width: 350px;
    height: 350px;
    border-radius: 200px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.text-in-work {
    color: #505559;
    max-width: 1000px;
    margin: 0 auto 0px;
    margin-top: 50px;
    font: 300 20px/32px "Arial";
    text-align: justify;
    line-height: 1;

}

.player-in-work {
    display: inline-block;
}