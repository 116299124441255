@-webkit-keyframes doublePulse {
    0% {
        -webkit-transform: scale(.6);
        transform: scale(.6);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes doublePulse {
    0% {
        -webkit-transform: scale(.6);
        transform: scale(.6);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}



.construction-tooltip-component {
}


a {
    color: #0084ff
}

a:hover,a:focus {
    color: #003566
}

.btn-primary {
    background: #0084ff
}

.btn-warning {
    background: #f62
}

.btn-block {
    background: #0084ff
}

header.row .navbar {
    background: #fff
}

header.row .navbar .navbar-header .navbar-toggle {
    background: 0 0;
    border: none
}

header.row .navbar .navbar-header .navbar-toggle span {
    background: #374048
}

header.row .navbar .navbar-header .navbar-toggle[aria-expanded=true] span {
    background: #778897
}

header.row .navbar.is-scrolling {
    background: #fff;
    -webkit-box-shadow: 0 2px 42px rgba(0,0,0,.1);
    box-shadow: 0 2px 42px rgba(0,0,0,.1)
}

header.row .navbar.is-scrolling .nav li a {
    color: #8f969d
}

header.row .navbar.is-scrolling .nav li.active a {
    color: #0084ff
}

header.alt-bg .navbar-default .nav>li>a {
    color: #fff
}

header.alt-bg .navbar .navbar-header .navbar-toggle span {
    background: #fff
}

header.alt-bg .navbar.is-scrolling .navbar-header .navbar-toggle span {
    background: #374048
}

.navbar-default .nav li a {
    color: #8f969d
}

.navbar-default .nav li a:hover,.navbar-default .nav li a:focus {
    color: #374048;
    background: 0 0
}

.navbar-default .nav li.active a {
    background: 0 0;
    color: #f62
}

.navbar-default .navbar-nav>.open>a,.navbar-default .navbar-nav>.open>a:hover,.navbar-default .navbar-nav>.open>a:focus {
    background-color: transparent;
    color: #f62
}

.navbar-default .navbar-nav>.active>a,.navbar-default .navbar-nav>.active>a:hover,.navbar-default .navbar-nav>.active>a:focus {
    color: #f62
}

.top-banner h2 {
    color: #374048
}

.top-banner p {
    color: #8a9095
}

body.home header .navbar {
    background: 0 0
}

.section-header:after {
    background: #eee
}

.section-header h2 {
    color: #374048
}

.section-header p {
    color: #969da3
}

.section-header.v2:after {
    background: #f6f8fa
}

.section-header.v2 p {
    color: #4e575f
}

.feature-note {
    color: #374048 /*tooltip*/
}

.feature-note .indicator .plus-before {
    border-color: #374048 /*tooltip*/;
}

.feature-note .indicator .plus-after {
    background: #374048 /*tooltip*/
}

.feature-note .indicator .plus-icon .plus {
    background: #374048 /*tooltip*/;
    color: #fff
}

.feature-note .feature-name:before {
    background: #374048 /*tooltip*/
}

.feature-note .feature-name:after {
    background: #374048 /*tooltip*/
}

.how-it-works {
    background: #f8fbfd
}

.work-process h3 {
    color: #374048
}

.work-process p {
    color: #737c85
}

.benefit .media .media-left span {
    color: #0084ff
}

.benefit .media .media-body h4 {
    color: #374048
}

.benefit .media .media-body p {
    color: #82898f
}

.left-right-contents {
    background: #f8fbfd
}

.left-right-contents .media .media-left span {
    color: #0084ff
}

.left-right-contents .media .media-body h4 {
    color: #374048
}

.left-right-contents .media .media-body p {
    color: #82898f
}

.collections .item .title {
    color: #0084ff
}

.collections .item .category {
    color: #b4bcc3
}

.collections .item .price {
    color: #f62
}

.the-watch {
    background: #f8fbfd
}

.the-watch-features ul li {
    color: #696f74
}

.split-column .texts h2 {
    color: #374048
}

.split-column .texts p {
    color: #c0cad0
}

.reviews {
    background: #f8fbfd
}

.review p {
    color: #353535
}

.featured-on {
    background: #f8fbfd
}

.featured-on h3 {
    color: #374048
}

.faq h4 {
    color: #374048
}

.faq p {
    color: #82898f
}

.tech-specs-row .tech-spec {
    border-top-color: #f4f5f6
}

.tech-specs-row h4 {
    color: #374048
}

.tech-specs-row ul li {
    color: #82898f
}

.team {
    background: #f8fbfd
}

.member .inner {
    background: #fff
}

.member .inner .texts h4 {
    color: #374048
}

.member .inner .texts h5 {
    color: #82898f
}

.timeline .timeline-row:before {
    background: #dee0e2
}

.timeline-post .bar-content .inner {
    color: #bfc2c5;
    background: #dee0e2
}

.timeline-post .half-side:before {
    border-color: #dee0e2;
    background: #fff
}

.timeline-post .half-side h4 {
    color: #6a737b
}

.timeline-post .half-side p {
    color: #b7bbbf
}

.timeline-post .half-side.date {
    color: #dadce0
}

.timeline-post .half-side.content .inner {
    background: #f5f8fa
}

.timeline-post .half-side+.half-side:before {
    border-color: #dee0e2;
    background: #fff
}

@media(max-width: 767px) {
    .timeline-post.has-content.odd .half-side+.half-side:before {
        border-color:#dee0e2
    }
}

.mobile-app {
    background: #000
}

.mobile-app h2 {
    color: #fff
}

.mobile-app p {
    color: #feffff
}

.newsletter-form .input-group .input-group-addon {
    background: 0 0;
    border-color: transparent
}

.newsletter-form .input-group .input-group-addon button {
    background: #0084ff
}

.newsletter-form .input-group .input-group-addon button:hover,.newsletter-form .input-group .input-group-addon button:focus {
    background: #374048
}

.newsletter-form p {
    color: #c0cad0
}

.contact-box {
    background: #fff
}

.contact-box h2 {
    color: #374048
}

.contact-box ul li {
    color: #a5aeb3
}

.contact-form {
    background: #fff
}

footer.row {
    background: #f8fbfd
}

footer.row .menu-rights p {
    color: #c3cbd1
}

.footer-menu li {
    color: #99ceff
}

.footer-menu li a {
    color: #99ceff
}

.footer-menu li a:hover,.footer-menu li a:focus {
    background: 0 0;
    color: #374048
}

.social-links {
    border-bottom-color: #e9eef2
}

.social-links ul li a {
    background: #99ceff;
    color: #fff
}

.social-links ul li a:hover,.social-links ul li a:focus {
    background: #374048
}

.page-header {
    background: #e9eaec
}

.page-header h2 {
    color: #374048
}

.page-header h4 {
    color: #828588
}

.widget-title {
    color: #374048
}

.widget-search form .form-control {
    border-color: #e2eaef;
    background: #f8fbfd
}

.widget-search form .form-control+.input-group-addon {
    border-color: #e2eaef;
    background: #f8fbfd
}

.widget-search form .form-control+.input-group-addon button {
    background-color: transparent;
    color: #ced5da
}

.recent-post .media-body h4 a {
    color: #656d75
}

.recent-post .media-body h5 {
    color: #c7d0d8
}

.recent-post .media-body h5 a {
    color: #65a9f0
}

.categories li a {
    color: #8e9eac
}

.categories li a:hover,.categories li a:focus {
    background: 0 0;
    color: #374048
}

.tag {
    color: #bdc7ce;
    background: #f8fbfd;
    border-color: #e0e8ee
}

.tag:hover,.tag:focus {
    background: #374048;
    border-color: #374048;
    color: #fff
}

.blog h2 a {
    color: #323f4a
}

.blog h2 a:hover,.blog h2 a:focus {
    color: #2979ff
}

.blog h5 {
    color: #a9b9c7
}

.blog h5 a {
    color: #52a8f5
}

.blog h5 a:hover,.blog h5 a:focus {
    color: #374048
}

.blog p {
    color: #8e9eac
}

.blog .btn-primary {
    background: #2979ff
}

.post-activity {
    border-right-color: #e5e7ea
}

@media(max-width: 499px) {
    .post-activity {
        border-bottom-color:#e5e7ea;
        border-top-color: #e5e7ea
    }
}

.post-activity .activity+.activity {
    border-top-color: #e5e7ea
}

@media(max-width: 499px) {
    .post-activity .activity+.activity {
        border-left-color:#e5e7ea
    }
}

.post-activity .activity a {
    color: #97a1aa
}

.author-about {
    background: #f8fbfd
}

.author-about .media-body h3 a {
    color: #292929
}

.author-about .media-body h3 a:hover,.author-about .media-body h3 a:focus {
    color: #2979ff
}

.author-about .media-body p {
    color: #8e9eac
}

.author-about .media-body ul li a {
    color: #fff;
    background: #bbd4e6
}

.author-about .media-body ul li a:hover,.author-about .media-body ul li a:focus {
    background: #374048
}

.comment-count {
    color: #292929
}

.comment {
    border-color: #d9e7f1
}

.comment p {
    color: #8e9eac
}

.commenter-link img {
    border-color: #d9e7f1
}

.commenter-name a {
    color: #292929
}

.commenter-name small a {
    color: #8e9eac
}

.add-comment h2 {
    color: #292929
}

.add-comment p {
    color: #c3d1de
}

.mfp-bg {
    background: #fff;
    opacity: .9
}

.product-box {
    background: #fff
}

.product-box .mfp-close {
    color: #8a9095
}

.product-img-gallery {
    background: #f8f8f8
}

#product-imgs .carousel-indicators li {
    background: #dadada
}

#product-imgs .carousel-indicators li.active {
    background: #0084ff
}

.product-desc .name {
    color: #848e96
}

.product-desc .edition {
    color: #374048
}

.product-desc .model {
    color: #a8afb5
}

.product-desc .price {
    color: #0084ff
}

.product-desc .price del {
    color: #808890
}

.product-desc .price .label {
    background: #ff840f
}

.product-desc .description {
    border-color: #eee
}

.product-desc .description p {
    color: #6f757b;
}

.product-desc .choose-form .choose-options {
    border-color: #eee
}

.product-desc .choose-form .option .form-label {
    color: #6c6e71
}

.product-desc .choose-form .option input[name=color]+label[for=color-1],.product-desc .choose-form .option input[name=os0]+label[for=color-1] {
    background: #2eafff
}

.product-desc .choose-form .option input[name=color]+label[for=color-1]:before,.product-desc .choose-form .option input[name=os0]+label[for=color-1]:before {
    border-color: #2eafff
}

.product-desc .choose-form .option input[name=color]+label[for=color-2],.product-desc .choose-form .option input[name=os0]+label[for=color-2] {
    background: #5c2eff
}

.product-desc .choose-form .option input[name=color]+label[for=color-2]:before,.product-desc .choose-form .option input[name=os0]+label[for=color-2]:before {
    border-color: #5c2eff
}

.product-desc .choose-form .option input[name=color]+label[for=color-3],.product-desc .choose-form .option input[name=os0]+label[for=color-3] {
    background: #ff2e99
}

.product-desc .choose-form .option input[name=color]+label[for=color-3]:before,.product-desc .choose-form .option input[name=os0]+label[for=color-3]:before {
    border-color: #ff2e99
}

.product-desc .choose-form .option input[name=color]+label[for=color-4],.product-desc .choose-form .option input[name=os0]+label[for=color-4] {
    background: #ffe62e
}

.product-desc .choose-form .option input[name=color]+label[for=color-4]:before,.product-desc .choose-form .option input[name=os0]+label[for=color-4]:before {
    border-color: #ffe62e
}

.product-desc .choose-form .option input[name=color]+label[for=color-5],.product-desc .choose-form .option input[name=os0]+label[for=color-5] {
    background: #2effa1
}

.product-desc .choose-form .option input[name=color]+label[for=color-5]:before,.product-desc .choose-form .option input[name=os0]+label[for=color-5]:before {
    border-color: #2effa1
}

.product-desc .choose-form .submit-area .btn:hover,.product-desc .choose-form .submit-area .btn:focus {
    color: #fff
}

.product-desc .choose-form .bootstrap-touchspin input {
    border-color: #e0e8ee;
    background: 0 0
}

.product-desc .choose-form .bootstrap-touchspin button {
    border-color: #e0e8ee
}

.product-desc .choose-form .bootstrap-touchspin button:before {
    color: #96a1a9
}

.product-desc .choose-form .bootstrap-select .dropdown-toggle {
    border-color: #e0e8ee;
    color: #374048
}

.product-desc .choose-form .bootstrap-select .dropdown-toggle .bs-caret {
    color: #96a1a9;
    border-left-color: #e0e8ee
}

.watch-review+button.mfp-close {
    background: #fff;
    color: #333
}

.watch-review+button.mfp-close:hover {
    color: #000
}

.pagination li a {
    background: #f7f7f7;
    color: #999
}

.pagination li a:hover,.pagination li a:focus {
    color: #fff;
    background: #2979ff
}

.pagination li.active a {
    color: #fff;
    background: #2979ff
}

.pagination li.active a:hover,.pagination li.active a:focus {
    background: #374048
}

.nav-pills>li.active>a,.nav-pills>li.active>a:focus,.nav-pills>li.active>a:hover {
    background-color: #0084ff
}

.help-block.text-danger {
    color: #ff5b58
}

.help-block.text-success {
    color: #53cc2e
}

.pricing {
    border-color: #e1e4e7
}

.pricing .pricing__title {
    color: #0084ff
}

.pricing .pricing__price {
    color: #f62
}

.pricing:hover {
    border-color: #c5cbd0
}

.pricing--featured {
    background-color: #0084ff
}

.pricing--featured .pricing__title {
    color: #fff
}

.pricing--featured .pricing__price {
    color: #fff
}

.pricing--featured .pricing__content li {
    color: #fff
}


.feature-note {
    display: inline-block;
    height: 115px;
    position: absolute;
    transition: all 300ms linear 0s
}



.feature-note .indicator {
    margin-top: 67px;
    width: 192px;
    transition: all 300ms linear 0s
}



.feature-note .indicator .plus-icon {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    padding: 8px;
    /*position: relative;*/
    transition: all 300ms linear 0s;
    transition-duration: 150ms
}

.feature-note .indicator .plus-before {
    content: '';
    width: 29px;
    height: 29px;
    position: absolute;
    top: -8.45px;
    right: -8.45px;
    border: 4px solid;
    border-radius: 100%;
    -webkit-animation: doublePulse 1.5s ease infinite;
    animation: doublePulse 1.5s ease infinite;
    animation-duration: 1.5s;
    z-index: 0;
    border-color: #374048 /*tooltip*/;
}

.feature-note .indicator .plus-after {
    content: '';
    height: 2px;
    display: block;
    margin-top: -17px;
    transition: all 300ms linear 0s;
    transition-duration: 150ms;
    width: 0
}


.feature-note .indicator .plus-icon .plus {
    border-radius: 100px;
    width: 100%;
    display: block;
    line-height: 31px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: all 300ms linear 0s
}


.feature-note .feature-name {
    /*text-transform: uppercase;*/
    white-space: pre-line;
    height: 91px;
    line-height: 20px;
    position: relative;
    letter-spacing: .2px
}

.feature-note .feature-name:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: 33px;
    left: calc(50% - 4px);
    transition: all 300ms linear 0s
}

.feature-note .feature-name:after {
    content: '';
    display: block;
    width: 2px;
    height: 66px;
    position: absolute;
    top: 41px;
    left: calc(50% - 1px);
    transition: all 300ms linear 0s;
}

.feature-note.right .indicator {
    float: left
}

.feature-note.right .indicator .plus-icon {
    float: left
}

.feature-note.right .indicator .plus-after {
    float: left;
    margin-left: 20px
}

.feature-note.right .indicator .plus-icon .plus {
    float: left
}

.feature-note.right.top {
    top: 10px;
    right: 220px
}


.feature-note.right.top .feature-name {
    float: left;
    margin-top: 30px;
    margin-left: -63px;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}


.feature-note.right.bottom {
    top: 290px;
    right: 221px
}



.feature-note.right.bottom .indicator {
    margin-top: 0
}

.feature-note.right.bottom .indicator .plus-after {
    width: 0
}

.feature-note.right.bottom .feature-name {
    float: left;
    right: 63px;
    padding-top: 70px;
    margin-top: 52px;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top
}



.feature-note.right.bottom .feature-name:before {
    top: 58px
}



.feature-note.right.bottom .feature-name:after {
    top: 0
}



.feature-note.left .indicator {
    float: right
}

.feature-note.left .indicator .plus-icon {
    float: right
}

.feature-note.left .indicator .plus-after {
    float: right;
    margin-right: 20px
}

.feature-note.left .indicator .plus-icon .plus {
    float: right
}

.feature-note.left.top {
    top: 115px;
    left: 205px
}



.feature-note.left.top .feature-name {
    margin-right: -61px;
    margin-top: 30px;
    float: right;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom
}





.feature-note.left.bottom {
    top: 395px;
    left: 122px;
    height: auto
}





.feature-note.left.bottom .indicator {
    margin-top: 0
}





.feature-note.left.bottom .feature-name {
    margin-right: 10px;
    float: right;
    height: auto;
    padding-top: 14px;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center
}



.feature-note.left.bottom .feature-name:before {
    position: relative;
    display: inline-block;
    top: 0;
    left: calc(100% + 5px)
}



.feature-note.left.bottom .feature-name:after {
    display: none
}



.feature-note.show-cont .indicator .plus-after {
    width: 102px
}

.feature-note.show-cont .feature-name {
    transition: -webkit-transform 150ms ease 150ms;
    transition: transform 150ms ease 150ms;
    transition: transform 150ms ease 150ms,-webkit-transform 150ms ease 150ms
}

.feature-note.show-cont.right.top .feature-name {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}

.feature-note.show-cont.right.bottom .indicator .plus-after {
    width: 237px
}



.feature-note.show-cont.right.bottom .feature-name {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}

.feature-note.show-cont.left.top .feature-name {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}

@media(max-width: 991px) {
    .feature-note.show-cont.left.bottom .indicator .plus-after {
        width:48px
    }
}

.feature-note.show-cont.left.bottom .feature-name {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}

.plus {
    bottom: 5px;
    height: 21px;
}

.plus-text {
    position: absolute;
    left: 7.3px;
    bottom: -18.2px;
    font-size: 16px;
}


.tooltip-description {
    font-size: 14px;
}

.feature-name {
    font: 600 25px/29px "Arial";
}

@media(max-width: 1285px) {

    .main-construction {
        height: 700px;
    }

    .image-construction {
        top:150px;
        right: 200px;
        width: 500px;
    }

    .feature-note.show-cont .indicator .plus-after {
        visibility: hidden;
    }

    .feature-note .indicator {
        width: 0px;
        visibility: hidden;
        pointer-events: none;
    }

    .feature-note.show-cont.left.top {
        margin-top: 10px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -750px;
    }

    .feature-note .feature-name:after {
        visibility: hidden;
    }

    .feature-name {
        font: 600 15px/29px "Arial";
        width: 200px;
    }

    .feature-name:before {
        margin-top: 30px;
    }

    .tooltips-construction {
        right: 70px;
        top: 80px
    }

    .feature-name .plus-before {
        content: '';
        width: 13px;
        height: 13px;
        position: absolute;
        top: 57.5px;
        right: 90.55px;
        border: 3px solid;
        border-radius: 100%;
        -webkit-animation: doublePulse 1.5s ease infinite;
        animation: doublePulse 1.5s ease infinite;
        animation-duration: 1.5s;
        z-index: 0;
        border-color: #005293 /*tooltip*/;
    }
}


@media(max-width: 820px) {


    .feature-note.show-cont.left.top {
        margin-top: 10px;
        margin-left: 110px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -600px;
    }
}

@media(max-width: 595px) {

    .main-construction {
        height: 550px;
    }


    .image-construction {
        top:150px;
        right: 200px;
        width: 400px;
    }
}

@media(max-width: 480px) {

    .main-construction {
        height: 490px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -700px;
    }

    .image-construction {
        top:150px;
        right: 200px;
        width: 300px;
    }

    .feature-name {
        font: 600 10px/29px "Arial";
        width: 100px;
    }

    .feature-note.show-cont.left.top {
        margin-top: 10px;
        margin-left: 130px;
    }


    .feature-name .plus-before {
        top: 57.5px;
        right: 40.55px;
    }
}



@media(max-width: 340px) {

    .main-construction {
        height: 450px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -780px;
    }

    .image-construction {
        width: 270px;
    }
}

@media(max-width: 285px) {

    .main-construction {
        height: 400px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -810px;
    }

    .feature-name {
        font: 600 8px/29px "Arial";
        width: 100px;
    }

    .image-construction {
        width: 230px;
    }
    .feature-note.show-cont.left.top {
        margin-top: 10px;
        margin-left: 124px;
    }
}


@media(max-width: 270px) {
    .feature-note.show-cont.right.top {
        margin-top: -180px;
    }

    .feature-note.show-cont.right.top {
        margin-left: -880px;
    }
}

@media(max-width: 220px) {

    .feature-note.show-cont.right.top {
        margin-left: -920px;
    }
}

@media(max-width: 192px) {

    .feature-note.show-cont.right.top {
        margin-left: -940px;
    }
}

.feature-text-field {
    text-align: left;

}


